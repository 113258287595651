import validator from 'validator';
import { v4 as uuid } from 'uuid';
import { STORE } from '../../store';
import { emit } from '../../services/socket';
import { renderQuestion } from '../RenderQuestion';
import { httpRequest } from '../../services/network';
import { IQuestion } from '../../interfaces/Question';
import { resetStreamingState, updateEnvironment } from '../../actions/environment.action';
import { createNewReference, focus, scroll, sleep, stopVoiceOutput } from '../../services/shared';
import { newMessage, updateAIThreadId, updateSingleMessage, updateTextInput } from '../../actions';
import { actionBasedOnChatResponse, queryAIHandler, saveMessage, updateUUID } from '../../services/saveResponse';
import { setAttributeValue } from '../Response';
import { handleAppointment } from '../Appointments/Appointment-Answer';

const validateQuestions = ['EMAIL', 'NUMBER', 'NAME', 'APPOINTMENT', 'SMART_QUESTION'];
const excludeAnswers = ['SKIP', 'BACK'];

export const submitAnswer = async (answer: string, original = '', next = null, isMedia = false, mediaType = 'document', MID = '') => {
  try {

    // Function to stop previous answer voice output if a new answer or question for AI has been submitted
    stopVoiceOutput();

    let aiResult: any = {};
    let attribute;
    const state = STORE.getState();
    let activeQuestion = state.environment.activeQuestion;
    const customAttributesList = state.environment.customAttributesList;
    const replyMessage = state?.replyMessage;
    let appointmentAnswer = '';
    if (!answer) {
      return;
    }

    if (activeQuestion._attribute && customAttributesList?.length) {
      attribute = customAttributesList.find((customAttribute: any) => customAttribute._id === activeQuestion._attribute);
    }

    STORE.dispatch(updateTextInput({ value: '', original: '' }));

    const mid = !!MID ? MID : uuid();
    if (localStorage.getItem('agentId')) {
      activeQuestion = null
    }

    if (activeQuestion && activeQuestion.type?.toLowerCase() === 'ai') {

      STORE.dispatch(resetStreamingState());

      if (activeQuestion.suggestions?.length) {
        STORE.dispatch(updateEnvironment({ activeQuestion: { ...activeQuestion, suggestions: [] } }));
      }

      if (!!state.environment.aiResponse) {
        STORE.dispatch(updateEnvironment({ aiResponse: '' }));
      }
    }

    let media = [];
    if (isMedia) {
      media.push({
        caption: '',
        type: mediaType,
        url: answer
      })
    }

    emit('message', {
      text: answer,
      messageBy: 'user',
      mid,
      medias: media,
      ...(isMedia && { type: mediaType }),
      ...(Object.keys(replyMessage || {}).length && { _parent: replyMessage })
    });

    scroll();

    /**
     * Author: Satyam Sharma
     * Date: 22 Jan 2025
     * Description: 
     * 1. MID: is for messages that are being added from other locations, so added the check to avoid duplicacy on UI
     * Case 1: The message is added to the store from the Audio component
     */
    if (!MID) {
      STORE.dispatch(newMessage({
        label: answer,
        position: 'right',
        type: 'STATEMENT',
        mid,
        questionId: activeQuestion?.id,
        ...(isMedia && { medias: media, type: mediaType }),
        ...(Object.keys(replyMessage || {}).length && { _parent: replyMessage }),
        isValid: (activeQuestion?.type === 'name') ||
          (activeQuestion?.type === 'email' && validator.isEmail(answer.trim())) ||
          (activeQuestion?.type === 'phone' && isPhoneValid(activeQuestion, answer))
      }));
    } else {
      STORE.dispatch(updateSingleMessage({
        mid: MID,
        isTemp: false,
        label: answer,
        position: 'right',
        type: 'STATEMENT'
      }));
    }

    /**
     * - Merging Date and time of appointment into final answer.
     * - This formatted value is used to store the appointment details in a custom attribute.
     */
    if (activeQuestion && activeQuestion.appointment && activeQuestion.name === 'appointment' && state.appointmentMeta
      && state.appointmentMeta.selectedValues.date) {
      const selectedDate = state.appointmentMeta.selectedValues.date;
      appointmentAnswer = `${selectedDate} ${answer}`;
    }

    saveMessage({
      type: 'message',
      text: !!appointmentAnswer ? appointmentAnswer : answer,
      ...(activeQuestion?.type === 'phone' && { prefix: answer.split(' ')[0] }), // As phone component send value in this format 'dialcode number'
      messagedBy: 'user',
      questionId: activeQuestion?.id,
      shouldNotify: activeQuestion?.sendNotifications || false,
      attributeId: activeQuestion?._attribute || '',
      questionType: state?.environment?.liveChat
        ? 'live_chat'
        : (activeQuestion?.type || '').toLowerCase(),
      ...((attribute && attribute.key) && { mappedAttributeKeys: [attribute.key] }),
      ...(Object.keys(replyMessage || {}).length && { replyInfo: { mid: replyMessage.mid } }),
      mid,
      medias: media,
      isErrorMessage: false,
      ...(isMedia && { type: mediaType }),
    })
      .catch((error) => console.log(error));

    scroll();

    // Action based on chat response
    actionBasedOnChatResponse(activeQuestion, answer, state.environment._chatWindowUser)
    actionBasedOnChatResponse(activeQuestion, answer, state.environment._chatWindowUser, state.environment._user)
      .catch(error => console.log(error));

    // STORE.dispatch(updateMessage({}));
    STORE.dispatch(updateTextInput({
      status: state.environment.liveChat || state.environment.enableTextInput || state.text.status,
      value: '',
      original: ''
    }));

    if (!activeQuestion) {
      return;
    }


    const result = await handleAppointment(answer);
    /**
     * The result will be a boolean value each time we call the handler and with the result value we will hold the flow,
     * or will skip to the next/remaining portion of code ,if true return directly because true means we have render one,
     * of the above case successfully.
     */
    if (result) {
      return;
    }

    setAttributeValue(activeQuestion, answer, state?.environment?.customAttributesList || []);
    if (validateQuestions.includes(activeQuestion.type.toUpperCase()) && !excludeAnswers.includes(answer.toUpperCase())) {
      STORE.dispatch(updateTextInput({
        value: '', original: ''
      }));
      await sleep(0.5);
      const messageUuid = uuid();

      if (!state.environment.liveChat) {
        let errorText;
        if (activeQuestion.rephraseError) {
          errorText = rephraseError(activeQuestion, 'error')
        }
        switch (activeQuestion.type.toUpperCase()) {
          case 'EMAIL': {
            /**
             * Logic for Whether to Include or Exclude the domains in selecteddomainsList[] 
             */

            const questionData = { ...activeQuestion.message.configureDomains };
            let query;
            (questionData?.domainAction === 'include' && questionData?.selectedDomainsList?.length)
              ? query = (!(questionData?.selectedDomainsList || []).includes(answer.substr(answer.lastIndexOf('@') + 1).trim()))
              : query = ((questionData?.selectedDomainsList || []).includes(answer.substr(answer.lastIndexOf('@') + 1).trim()));

            if (query && validator.isEmail(answer.trim())) {
              if (activeQuestion.rephraseError) {
                errorText = rephraseError(activeQuestion, 'domainErrorMessage')
              }

              STORE.dispatch(newMessage({
                label: errorText || questionData.domainErrorMessage || 'This domain is not acceptable',
                position: 'left',
                type: 'STATEMENT',
                mid: messageUuid,
                isErrorMessage: true
              }));
              emit('message', {
                text: errorText || questionData.domainErrorMessage || 'This domain is not acceptable',
                messageBy: 'bot',
                mid: messageUuid
              });
              saveMessage({
                type: 'message',
                text: errorText || questionData.domainErrorMessage || 'This domain is not acceptable',
                messagedBy: 'bot',
                mid: messageUuid,
                isErrorMessage: true,
                questionType: (activeQuestion?.type || '').toLowerCase(),
                questionId: activeQuestion?.id,
              }).then().catch();

              scroll();
              focus();
              return;
            }
            if (validator.isEmail(answer.trim())) {
              updateUUID({
                email: answer,
                lead: true
              }).then().catch();
              STORE.dispatch(updateEnvironment({
                response: {
                  ...state.environment.response,
                  email: answer
                }
              }));
              sessionStorage.setItem('attendeeEmail', answer);
              break;
            }
            STORE.dispatch(newMessage({
              label: errorText || activeQuestion.message.error,
              position: 'left',
              type: 'STATEMENT',
              mid: messageUuid,
              isErrorMessage: true
            }));
            emit('message', {
              text: errorText || activeQuestion.message.error,
              messageBy: 'bot',
              mid: messageUuid,
            });
            saveMessage({
              type: 'message',
              text: errorText || activeQuestion.message.error,
              messagedBy: 'bot',
              mid: messageUuid,
              isErrorMessage: true,
              questionType: (activeQuestion?.type || '').toLowerCase(),
              questionId: activeQuestion?.id,
            }).then().catch();

            scroll();
            focus();
            return;
          }
          case 'NUMBER': {
            if (validator.isNumeric(answer.trim())) {
              break;
            }
            emit('message', {
              text: errorText || activeQuestion.message.error,
              messageBy: 'bot',
              isErrorMessage: true,
              mid: messageUuid,
            });

            STORE.dispatch(newMessage({
              label: errorText || activeQuestion.message.error,
              position: 'left',
              type: 'STATEMENT',
              mid: messageUuid,
              isErrorMessage: true
            }));
            saveMessage({
              type: 'message',
              text: errorText || activeQuestion.message.error,
              messagedBy: 'bot',
              mid: messageUuid,
              isErrorMessage: true,
              questionType: (activeQuestion?.type || '').toLowerCase(),
              questionId: activeQuestion?.id,
            }).then().catch();

            scroll();
            focus();
            return;
          }
          case 'NAME': {
            if (answer.trim().length >= activeQuestion.maxRange) {
              emit('message', {
                text: errorText || activeQuestion.message.error,
                messageBy: 'bot',
                mid: messageUuid,
              });

              STORE.dispatch(newMessage({
                label: errorText || activeQuestion.message.error,
                position: 'left',
                type: 'STATEMENT',
                mid: messageUuid,
                isErrorMessage: true
              }));
              saveMessage({
                type: 'message',
                text: errorText || activeQuestion.message.error,
                messagedBy: 'bot',
                mid: messageUuid,
                isErrorMessage: true,
                questionType: (activeQuestion?.type || '').toLowerCase(),
                questionId: activeQuestion?.id,
              }).then().catch();
              STORE.dispatch(updateTextInput({
                status: true,
                value: '', original: ''
              }));

              scroll();
              focus();
              return;
            }
            updateUUID({
              name: answer
            }).then().catch();
            STORE.dispatch(updateEnvironment({
              response: {
                ...state.environment.response,
                name: answer
              }
            }));
            sessionStorage.setItem('name', answer);
            break;
          }
          case 'APPOINTMENT': {

            if (!state.environment.preview && sessionStorage.getItem('attendeeEmail') && validator.isEmail(sessionStorage.getItem('attendeeEmail') || '')) {

              await httpRequest('POST', 'process-appointment', {
                email: sessionStorage.getItem('attendeeEmail'),
                _user: state.environment._user,
                _bot: state.environment._id,
                start: new Date(answer).toISOString(),
                end: new Date(new Date(answer).setMinutes(new Date(answer).getMinutes() + activeQuestion.appointment.interval)).toISOString()
              });
            }
            break;

          }
          case 'SMART_QUESTION': {
            let found = false;
            let options = createNewReference(activeQuestion.options);
            options = options.sort((a: any, b: any) => (a.keywordType.toLowerCase() === 'contain') ? 1 : -1);
            let index: any;

            for (const option of options) {
              if (option.keywordType.toLowerCase() === 'exact' && option.smartKeywords.includes(answer)) {
                found = true;

                if (option.next.target === 'end') {
                  STORE.dispatch(updateTextInput({
                    status: false
                  }));
                  return false;
                }

                index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === option.next.target);
                renderQuestion(index);

                scroll();
                focus();
                return;
              } else if (option.keywordType.toLowerCase() === 'contain') {
                for (const keyword of option.smartKeywords) {
                  if (answer.toLowerCase().includes(keyword.toLowerCase()) && !found) {
                    found = true;

                    if (option.next.target === 'end') {
                      STORE.dispatch(updateTextInput({
                        status: false
                      }));
                      return false;
                    }

                    index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === option.next.target);
                    renderQuestion(index);
                    return;
                  }
                }
              }
            }

            if (found) {
              return;
            }

            if (activeQuestion.next.target) {
              if (activeQuestion.next.target === 'end') {
                STORE.dispatch(updateTextInput({
                  status: false
                }));
                return false;
              }
              index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion.next.target);
              renderQuestion(index);
              return;
            }
            break;
          }
          default: {

            scroll();
            focus();
            return;
          }
        }

        scroll();
      }
    }

    STORE.dispatch(updateEnvironment({
      refresh: state.environment.liveChat,
      lastQuestion: activeQuestion?.id,
      skip: false,
      back: false,
      lastUserMessage: answer
    }));

    if (activeQuestion?.includeInLeads) {
      updateUUID({
        lead: true,
        customParameters: {
          [activeQuestion.label]: answer
        }
      }).then().catch();
    }


    scroll();
    focus();
    let index: number;
    if (next) {
      index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === next);
      if (index === -1) {
        index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion?.id);
        index = index + 1;
      }
    } else {
      if (activeQuestion.next.target) {
        index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion.next.target);
        if (index === -1) {
          index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion?.id);
          index = index + 1;
        }
      } else {
        index = state.flows[0].questions.findIndex((q: IQuestion) => q.id === activeQuestion?.id);
        index = index + 1;
      }
    }

    const constants = ['STATEMENT', 'IMAGE', 'CONTACT', 'VIDEO'];
    if (!constants.includes(activeQuestion.label.toUpperCase())) {
      if (!localStorage.getItem('questions')) {
        localStorage.setItem('questions', JSON.stringify([]));
      }
      if (localStorage.getItem('questions')) {
        const qs = JSON.parse(localStorage.getItem('questions') || '');
        localStorage.setItem('questions', JSON.stringify([...qs, {
          question: activeQuestion.label,
          questionId: activeQuestion?.id,
          answer
        }]));
        emit('update-user-details', {
          messages: [...qs, {
            question: activeQuestion.label,
            questionId: activeQuestion?.id,
            answer
          }]
        });
      }
    }


    if (activeQuestion.type.toUpperCase() !== 'AI') {
      STORE.dispatch(updateTextInput({
        status: true,
        value: '', original: ''
      }));
    }

    if (activeQuestion.next.target === 'end' || next === 'end') {
      STORE.dispatch(updateTextInput({
        status: false
      }));
      return false;
    }

    if (activeQuestion.type.toUpperCase() === 'AI') {
      const { aiResponseBufferEnabled, aiResponseBufferDuration = 1 } = activeQuestion.aiConfig || {};
      const batchedMessages = `${state.environment.batchedMessages} ${answer}`;

      if (aiResponseBufferEnabled) {
        STORE.dispatch(updateEnvironment({
          batchedMessages
        }));

        /**
         * Date: 24/Jan/2025
         * Author: Amit Kumar
         * Summary: `isAiResponding` flag for managing AI typing UI behavior
         * Description:
         * The `isAiResponding` variable is used to control the display of the AI typing indicator and manage the input field behavior when `aiResponseBufferEnabled` is active. 
         *
         * Key Points:
         * - If `aiResponseBufferEnabled` is active:
         *   - The AI typing indicator is displayed for the duration of `aiResponseBufferDuration`.
         *   - The input field is not disabled while the buffer duration is incomplete, allowing the user to continue typing.
         * - The `isAiResponding` flag ensures proper handling of the typing UI when AI is actively responding and aligns with the configured buffer duration logic.
         *
         * Usage:
         * - Display the typing UI during AI responses.
         * - Prevent premature disabling of the input field while respecting the buffer duration.
         */
        STORE.dispatch(updateEnvironment({
          isAiResponding: true
        }));

        const currentTimerRef = state.environment.timerRef;
        if (currentTimerRef) {
          clearTimeout(currentTimerRef);
        }
        const newTimerRef = setTimeout(async () => {
          STORE.dispatch(updateEnvironment({
            timerRef: null
          }));
          await aiMessageHandler(batchedMessages, index);
        }, (aiResponseBufferDuration * 1000));
        STORE.dispatch(updateEnvironment({
          timerRef: newTimerRef
        }));
      } else {
        await aiMessageHandler(answer, index);
      }
    } else if (!state.environment.liveChat) {
      renderQuestion(index, aiResult?.aiResponse, false, aiResult.aiResponse?.mid || '');
    }
  } catch (error) {
    console.log(error);
  }
}


// FIXME: These type of handlers should be declared in separate files rather than a common utility to make use of lazy loading properly. WIll discuss in person
export const isPhoneValid = (activeQuestion: IQuestion | any, answer: string) => {
  const minLength = activeQuestion?.phoneValidation?.minLength ?? 7;
  const maxLength = activeQuestion?.phoneValidation?.maxLength ?? 15;
  const isPhoneValidationEnabled = activeQuestion?.phoneValidation?.isEnabled ?? true;

  if (isPhoneValidationEnabled) {
    return validator.isMobilePhone(answer);
  }

  return (answer.length >= minLength && answer.length <= maxLength);
};

export const rephraseError = (question: any, type: 'error' | 'domainErrorMessage' | 'unavailability') => {
  const { environment } = STORE.getState();

  const rephrasedQue = (environment.rephrasedFlow || []).find((q: any) => q.id === question.id) ||
    (environment.rephrasedRevisitFlow || []).find((q: any) => q.id === question.id) ||
    (environment.rephrasedRedirectFlow || []).find((q: any) => q.id === question.id);

  if (!rephrasedQue) {
    return '';
  }

  if (type === 'error') {
    if (rephrasedQue && rephrasedQue.message?.rephrasedErrorList && rephrasedQue.message.rephrasedErrorList.length) {
      return rephrasedQue.message.rephrasedErrorList[Math.floor(Math.random() * 10)]
    }
  }

  if (type === 'domainErrorMessage') {
    if (rephrasedQue && rephrasedQue.message?.configureDomains?.rephrasedDomainErrorMessageList && rephrasedQue.message.configureDomains.rephrasedDomainErrorMessageList.length) {
      return rephrasedQue.message.configureDomains.rephrasedDomainErrorMessageList[Math.floor(Math.random() * 10)]
    }
  }

  if (type === 'unavailability') {
    if (rephrasedQue && rephrasedQue.message?.rephrasedUnavailableList && rephrasedQue.message.rephrasedUnavailableList.length) {
      return rephrasedQue.message.rephrasedUnavailableList[Math.floor(Math.random() * 10)]
    }
  }
  return '';

}

export const aiMessageHandler = async (messageText: string, index: number) => {
  try {
    const { environment, ai } = STORE.getState();
    let activeQuestion = environment.activeQuestion;
    STORE.dispatch(updateTextInput({
      status: false,
      value: '', original: ''
    }));

    STORE.dispatch(updateEnvironment({
      typing: true,
      isAiResponding: false,
      batchedMessages: ''
    }));

    const aiResult: any = await queryAIHandler(
      {
        query: messageText,
        _bot: environment._id,
        hash: 'upbu8kseWBhofnorUuICXTmX5',
        userDetails: { _agency: environment._agency, _user: environment._user, isKeyConfigured: ai.isKeyActive },
        aiConfig: {
          ...activeQuestion.aiConfig,
          aiResponseBufferEnabled: undefined,
          aiResponseBufferDuration: undefined

        },
        ...(ai?.threadId && { threadId: ai?.threadId }
        )
      });

    if (aiResult?.threadId) {
      updateUUID({ threadId: aiResult.threadId })
        .catch(error => console.log(`error in updating threadId: ${error}`));

      STORE.dispatch(updateAIThreadId({
        threadId: aiResult.threadId
      }));
    }

    if (!environment.liveChat) {
      renderQuestion(index, aiResult?.aiResponse, false, aiResult.aiResponse?.mid || '');
    }
  } catch (err) {
    throw err;
  }
}